<template>
    <section class="pa-5">
        <NavButton />
      <div class="d-flex justify-between">
        <p class="text-h6 secondary--text text-uppercase">Contratos a liquidar</p>
      </div>

      <!--Filtros-->
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            label="Código"
            outlined
            hide-details
            clearable
            @keyup="filternumero"
            @click:clear="getContratos()"
            v-model="filters.numero"
          />
        </v-col>

        <v-col cols="12" sm="12" md="8">
          <v-select
            label="Forma contratación"
            :items="ctlModalidades"
            item-value="id"
            item-text="nombre"
            outlined
            clearable
            hide-details
            v-model="filters.id_forma_contratacion"
            @change="getContratos()"
          />
        </v-col>
      </v-row>
      <data-table-component
        v-models:select="perPage"
        v-models:pagina="page"
        :headers="headers"
        :items="items.data"
        @paginar="paginate"
        :total_registros="total_registros"
      >
        <template v-slot:item.proceso_compra="{ item }">
          <span>
            <b>{{ item.codigo_proceso }} - </b>
            {{  item.nombre_proceso  }}
          </span>
        </template>
        <template v-slot:[`item.fecha_hora_contratacion`]="{ item }">
          {{ moment(item.fecha_hora_contratacion).format("DD/MM/YYYY") }}
        </template>
        <template #[`item.monto_adjudicado`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 8,
            }).format(item.monto_adjudicado)
          }}
        </template>
        <template v-slot:[`item.acciones`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="goToSeguimiento(item)"
                v-bind="attrs"
                v-on="on"
                color="secondary"
              >
                mdi-eye
              </v-icon>
            </template>
            <span> {{ item.id_estado_contrato === 2 ? 'Ver seguimiento' : 'Ver liquidación' }}</span>
          </v-tooltip>
        </template>
      </data-table-component>
    </section>
  </template>
  <script>
  import DataTableComponent from "@/components/DataTableComponent.vue";
import NavButton from "@/components/utils/NavButton.vue";
import { createLoadable, createPageable, setPageableResponse, toggleLoadable, togglePageable } from "@/utils/loadable";
import moment from "moment";

  export default {
    name: "ProcesosAsignadosView",
    components: {
      DataTableComponent,
      NavButton
    },
    data: () => ({
      filters: {
        id_forma_contratacion: null,
        fecha_adjudicacion: null,
        numero: null,
      },
      menuFecha: false,
      headers: [
        {
          text: "Código",
          value: "numero",
          align: "center",
        },
        {
          text: "Proceso de compra",
          value: "proceso_compra",
        },
        {
          text: "Nombre contrato",
          value: "nombre_contrato",
        },
        {
          text: "Fecha contratación",
          value: "fecha_hora_contratacion",
        },
        {
          text: "Monto adjudicado ($)",
          value: "monto_adjudicado",
          align: "center",
        },
        {
          text: "Plazo contractual",
          value: "plazo_contractual",
          align: "center",
        },
        {
          text: "Proveedor",
          value: "nombre_comercial",
        },
        {
          text: "Institución",
          value: "institucion",
        },
        {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable:false
        },
      ],
      items: createPageable([]),
      totalRows: 0,
      page: 1,
      perPage: 10,
      ctlModalidades: [],
      timer: null,
    }),
    computed: {
      dateFormatted() {
        return this.filters.fecha_adjudicacion
          ? moment(this.filters.fecha_adjudicacion).format("DD/MM/YYYY")
          : "";
      },
      total_registros(){
        return this.items.pagination.total_rows;
      }
    },
    methods: {
        paginate(params) {
            const { cantidad_por_pagina, pagina } = params;
            this.perPage = cantidad_por_pagina;
            this.page = pagina;
            this.getContratos();
        },
        filternumero() {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            this.timer = setTimeout(async () => {
                await this.getContratos();
            }, 500);
        },

        async getContratos() {
            togglePageable(this.items)
            const { data, headers } = await this.services.SeguimientoContractual.obtenerContratos({
                ...this.filters,
                page: this.page,
                per_page: this.perPage,
                pagination: true
            })
            setPageableResponse(this.items, data, headers)
        },
        goToSeguimiento(item) {
            if (item.id_estado_contrato === 2) {
                this.$router.push({
                    name: "seguimiento-orden-compra",
                    params: {
                        idContrato: item.id,
                        idProveedor: item.id_referencia
                    },
                });
            } else {
                this.$router.push({
                    name: "liquidacion-orden-compra",
                    params: { idContrato: item.id }
                })
            }
        },
        async fetchModalidades() {
            const response = await this.services.Paac.getModalidades({
                compra_tradicional: true,
            });
            if (response) {
                this.ctlModalidades = response.data.forma_contratacion;
            }
        },
        realizarPago() {
            this.$router.push({
                path: "/contratos",
            });
        }
    },
    created() {
      this.getContratos();
      this.fetchModalidades();
    },
  };
  </script>
  